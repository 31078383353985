import { each, filter, isEmpty, orderBy } from 'lodash-es'

interface FlatTree {
  id: string;
  parentId: string;
  order: string;
}

interface Tree extends FlatTree {
  children?: Tree[];
}

export const unflattenTree = (flatTree: FlatTree[], parent?: Tree, tree: Tree[] = []) => {
  const orderedFlatTree = orderBy(flatTree, ['order'], ['asc'])

  const children = filter(orderedFlatTree, (child) =>
    (!child.parentId && !parent?.id) || child.parentId === parent?.id
  )

  if (!isEmpty(children)) {
    if (!parent?.id) {
      // add children to root node
      tree = children
    } else {
      // set the parent node's children
      parent.children = children
    }

    // populate sub nodes until we reach each leaf
    each(children, (child) => unflattenTree(orderedFlatTree, child))
  }

  // return final (sub) tree(s)
  return tree
}

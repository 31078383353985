<template>
  <div>
    <template v-for="(element, key) in list">
      <template v-if="hasChildren(element) && getChildList(element).length > 0">
        <div :key="key" :style="`padding-left: ${level}%;`" >
          <div class="mb-1" v-b-toggle="`element-${getId(element)}`">
            <fa-icon class="on-open mr-1" :icon="['fas', 'chevron-down']" size="sm"></fa-icon>
            <fa-icon class="on-closed mr-1" :icon="['fas', 'chevron-right']" size="sm"></fa-icon>
            {{ displayLabel(element) }}
          </div>
          <b-collapse :visible="isOpen" :id="`element-${getId(element)}`">
            <tree :list="getChildList(element)" :children-key="childrenKey" :id="id" :label-key="labelKey" :is-open="isOpen" :level="getNewLevel()" />
          </b-collapse>
        </div>
      </template>
      <template v-else>
        <div :key="key" class="no-child mb-1" :style="`padding-left: ${level}%;`">
          {{ displayLabel(element) }}
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { get } from 'lodash-es'
import { BCollapse, VBToggle } from 'bootstrap-vue'
import Tree from '@/components/common/Tree'

export default {
  name: 'Tree',
  components: {
    BCollapse,
    Tree
  },
  directives: {
    'b-toggle': VBToggle
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: [String, Function],
      default: ''
    },
    childrenKey: {
      type: [String, null],
      default: null
    },
    id: {
      type: [String, null],
      default: null
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    level: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getNewLevel () {
      return this.level + 1
    },
    displayLabel (element) {
      if (typeof this.labelKey === 'function') {
        return this.labelKey(element)
      }

      return get(element, this.labelKey) || ''
    },
    hasChildren (element) {
      return get(element, this.childrenKey) || false
    },
    getChildList (element) {
      return get(element, this.childrenKey) || []
    },
    getId (element) {
      return get(element, this.id) || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.no-child {
  cursor: no-drop;
}
.collapsed > .on-open,
.not-collapsed > .on-closed {
  display: none;
}
</style>

<template>
  <app-layout>
    <div class="px-4 p-sm-5 container container-left">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Catégories des produits</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-12">
                  <div class="mb-4">
                    <async-object
                      should-fetch
                      :fetch-method="() => $store.dispatch('pages/fetchProductsCategoriesDocumentation')"
                    >
                      <div v-html="compiledMarkdown" />
                    </async-object>
                  </div>
                  <div class="form-inline">
                    <div class="d-flex justify-content-between mobile-wid-100 w-100">
                      <div class="mobile-wid-100">
                        <div class="input-group mb-3 mobile-wid-100 w-100">
                          <input v-model="textFilter" type="text" class="form-control" placeholder="Filtrer par nom / code">
                          <div class="input-group-prepend">
                            <span @click.prevent="reset()" class="input-group-text btn btn-outline-danger" id="basic-addon1">
                              <fa-icon :icon="['fas', 'times']" size="sm"></fa-icon>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="mobile-wid-100 align-right">
                        <span class="btn btn-link" @click.prevent="toggle()">
                          <span v-if="!isOpen">Ouvrir tout</span>
                          <span v-else>Fermer tout</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isReady && isSearched">
                <tree v-if="filteredList.length > 0" :list="filteredList" children-key="children" :label-key="label" id="id" :is-open="isOpen" />
                <div v-else class="row mb-5">
                  <div class="col-12 text-center fw-700">
                    Aucune catégorie trouvée
                  </div>
                </div>
                <p class="text-muted mt-5">
                  *Si vous ne trouvez pas la catégorie de votre choix, n'hésitez pas de créer une demande dans <router-link to="/support/requests">la page de support</router-link>.
                </p>
              </div>
              <b-spinner v-else></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<script>
import { get, cloneDeep } from 'lodash-es'
import http from '@/utils/http'
import Tree from '@/components/common/Tree'
import { unflattenTree } from '@/utils/flatToTree'
import AsyncObject from '@/components/common/AsyncObject'
import sanitizeHtml from 'sanitize-html'
import marked from 'marked'

export default {
  components: {
    AsyncObject,
    Tree
  },
  data () {
    return {
      isReady: false,
      isSearched: true,
      list: [],
      isOpen: true,
      textFilter: ''
    }
  },
  computed: {
    filteredList () {
      this.search(false)
      let items = cloneDeep(this.list) || []

      if (this.textFilter) {
        const searchString = this.textFilter.toLowerCase()

        items = this.filterList(items, searchString)

        this.toggle(true)
      }

      this.search(true)
      return items
    },
    content () {
      return this.$store.state.pages.productCategoriesDocumentation.content
    },
    compiledMarkdown () {
      return this.content ? sanitizeHtml(marked(this.content, { breaks: true, gfm: true })) : ''
    }
  },
  methods: {
    search (state) {
      if (state) {
        setTimeout(() => {
          this.isSearched = state
        }, 100)
      } else {
        this.isSearched = state
      }
    },
    reset () {
      this.textFilter = ''
      this.isOpen = true
    },
    filterList (items, text) {
      const newList = []

      for (const item of items) {
        if (item.children && item.children.length) {
          item.children = this.filterList(item.children, text)
        }

        if ((item.children && item.children.length) ||
            get(item, 'name', '').toLowerCase().indexOf(text) > -1 ||
            get(item, 'shortId', '').toString().indexOf(text) > -1
        ) {
          newList.push(item)
        }
      }

      return newList
    },
    toggle (force) {
      this.isOpen = force !== undefined ? force : !this.isOpen
    },
    label (element) {
      return `${element.name} (${element.shortId})`
    }
  },
  async created () {
    const { data } = await http.get('/v1/product-categories/flat')
    this.list = unflattenTree(data)

    setTimeout(() => {
      this.isReady = true
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.align-right {
  text-align: right;
}
.fw-700 {
  font-weight: 700;
}
</style>
